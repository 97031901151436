import {
  NotificationType,
  PushNotification,
  PushNotificationFormatted,
  ScheduledNotification,
  ScheduledNotificationFormatted,
} from './types';

import { TargetGroup } from '../targetGroups';
import dayjs from 'dayjs';
import { standardDateFormat } from 'src/utils';
import { translateTargetGroup } from '../targetGroups/translateTargetGroup';

/**
 * Takes in notifications from server and formats objects into strings to display in table.
 *
 * @param notifications - PushNotifications from server
 * @returns - Formatted PushNotifications to work with table implementation
 */
export const formatPushNotifications = (notifications: PushNotification[]) => {
  if (!notifications) return []; // Nullcheck

  return notifications.map(
    ({ targetGroups, totalSeen, totalSent, date, type, ...rest }) => {
      return {
        ...rest,
        type,
        ...convertValuesToStrings(date, targetGroups, type),
        openingStats: `${totalSeen} st av ${totalSent} st`,
      } as PushNotificationFormatted;
    }
  );
};

export const formatScheduledPushNotifications = (
  notifications: ScheduledNotification[]
) => {
  if (!notifications) return [];

  return notifications.map(
    ({ targetGroups, date, type, ...rest }) =>
      ({
        ...rest,
        type,
        ...convertValuesToStrings(date, targetGroups, type),
        scheduleTime: dayjs(rest.scheduleTime).format('YYYY-MM-DD HH:mm'),
        scheduleTimeFormatted: dayjs(rest.scheduleTime).format(
          standardDateFormat
        ),
      } as ScheduledNotificationFormatted)
  );
};

// Helper function
const convertValuesToStrings = (
  date: string,
  targetGroups: TargetGroup[],
  type: NotificationType
) => {
  return {
    date: dayjs(date).format(standardDateFormat),
    targetGroupString: targetGroups.map(
      (t) => `${translateTargetGroup(t.group)} (${t.count} st)`
    )[0],
    typeString: type === 'Alarm' ? 'Larm' : 'Pushnotis',
  };
};
