import { FieldValues, useForm } from 'react-hook-form';
import {
  Form,
  FormLabel,
  FormTextInput,
  InformationModal,
} from 'ck-components';

import { BaseModalProps } from 'src/components/Modals/types';
import { toast } from 'react-hot-toast';
import { useEditCommittee } from 'src/queries/organisations/useEditCommittee';

interface FormValues extends FieldValues {
  email: string;
}

export const ChangeModeratorModal = (
  props: Omit<BaseModalProps, 'children'> & {
    committeeId: string;
    committeeName: string;
  }
) => {
  const M_editCommittee = useEditCommittee();
  const form = useForm<FormValues>();
  const { handleSubmit, reset } = form;

  const onSubmit = (data: FormValues) => {
    toast
      .promise(
        M_editCommittee.mutateAsync({
          id: props.committeeId,
          email: data.email,
        }),
        {
          loading: 'Laddar...',
          success: `Inbjudan skickad till ${data.email} om att bli moderator för
              ${props.committeeName}
            `,
          error: 'Kunde inte uppdatera kommittén',
        },
        {
          duration: 8000,
        }
      )
      .then(() => {
        // Reset form
        reset();

        // Close edit modal
        props.setIsOpen(false);
      });
  };

  return (
    <InformationModal
      {...props}
      customStyle='w-[692px]'
      onClose={reset}
      showCloseButton={false}
    >
      <Form
        onSubmit={handleSubmit(onSubmit)}
        submitButtonLabel='Skicka inbjudan'
        showCancelButton
        form={form}
        onCancel={() => {
          reset();
          props.setIsOpen(false);
        }}
        // TODO: Set these styles in the component as defaults
        submitButtonStyle='w-[200px] px-4 h-[60px]'
        cancelButtonStyle='w-[200px] px-4 h-[60px]'
        centerButtons
        isSubmitting={M_editCommittee.isLoading}
      >
        <p>
          Byter du moderator för kommittén övergår den nya moderatorn först när
          inbjudan blivit accepterad.
        </p>
        <FormLabel label='E-POSTADRESS'>
          <FormTextInput
            name='email'
            parentForm={form}
            errorsAlwaysTakeUpSpace={false}
            options={{
              required: 'E-postadress måste anges',
              pattern: {
                value: /^\S+@\S+$/i,
                message: 'Ogiltig e-postadress',
              },
            }}
          />
        </FormLabel>
      </Form>
    </InformationModal>
  );
};
