import { Navigate, useParams } from 'react-router-dom';

import { CreateAlarmnForm } from 'src/widgets/CreateAlarmForm.tsx/CreateAlarmForm';
import { FullScreenLoadingSpinner } from 'src/components/FullScreenLoadingSpinner';
import { MainTitle } from 'ck-components';
import { useAlarmPrefill } from 'src/queries/alarm/useAlarmPrefill';

interface Props {
  isCopy?: boolean;
}
export function AlarmCreatePage({ isCopy }: Props) {
  // Id provided by the route when editing a notification
  const { id } = useParams();
  const { data, isLoading } = useAlarmPrefill(id, Boolean(isCopy));

  // Get the values of the notification to edit
  const prefill = data?.prefill;

  if (isLoading) {
    return <FullScreenLoadingSpinner />;
  }

  if (!prefill && id) {
    // If a id exists without prefill, create a new alarm from scratch
    return <Navigate to='/push-notifications/alarm/create' replace={true} />;
  }

  const mainTitle = isCopy ? 'Kopiera larm' : 'Skapa larm';

  return (
    <div className='flex flex-1 flex-col'>
      <MainTitle label={mainTitle} customStyle='pb-8' />

      <CreateAlarmnForm prefill={prefill} isCopy={isCopy} />
    </div>
  );
}
