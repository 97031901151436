import { QK } from '../queryKeys';
import { TargetGroupsResponse } from './types';
import { fetchWithErrorThrowing } from 'ck-utils';
import { useQuery } from '@tanstack/react-query';

// Query hook
export const useTargetGroups = () =>
  useQuery({
    queryKey: QK.targetGroups,
    queryFn: getTargetGroups,
  });

// Query function
const getTargetGroups = () =>
  fetchWithErrorThrowing('/target-groups', {
    method: 'GET',
  })
    .then((res) => res.json())
    .then(({ targetGroups }: TargetGroupsResponse) => targetGroups);
