import {
  Form,
  FormLabel,
  FormTextInput,
  InformationModal,
} from 'ck-components';

import { InformationModalProps } from 'src/components/Modals/types';
import { PostCreateCommittee } from 'ck-queries';
import { useCreateCommittee } from 'src/queries/organisations/useCreateCommittee';
import { useForm } from 'react-hook-form';

// TODO: Generalize this modal to take any form as children
export const AddCommitteeModal = (
  props: Omit<InformationModalProps, 'children'> & { divisionId: string }
) => {
  const form = useForm<PostCreateCommittee>();
  const M_createCommittee = useCreateCommittee();

  const { handleSubmit, reset } = form;

  const onSubmit = (formData: PostCreateCommittee) => {
    // Add divisionId to form data
    const postData = { ...formData, divisionId: props.divisionId };

    M_createCommittee.mutateAsync(postData).then(() => {
      reset();
      props.setIsOpen(false);
    });
  };

  return (
    <InformationModal
      {...props}
      customStyle='w-[692px]'
      onClose={reset}
      showCloseButton={false}
    >
      <Form
        submitButtonLabel='Skapa och skicka'
        showCancelButton={true}
        form={form}
        onSubmit={handleSubmit(onSubmit)}
        onCancel={() => {
          reset();
          props.setIsOpen(false);
        }}
        submitButtonStyle='w-[200px] px-4 h-[60px]'
        cancelButtonStyle='w-[200px] px-4 h-[60px]'
        centerButtons
        isSubmitting={M_createCommittee.isLoading}
      >
        <p>
          En inbjudan om att skapa ett konto kommer att skickas till angiven
          e-postadress. Inbjudan är aktiv under en vecka.
        </p>
        <FormLabel label='NAMNGE KOMMITTÉ'>
          <FormTextInput
            name='name'
            parentForm={form}
            errorsAlwaysTakeUpSpace={false}
            options={{
              required: 'Namn måste anges',
            }}
          />
        </FormLabel>
        <FormLabel label='MODERATOR'>
          <FormTextInput
            name='email'
            parentForm={form}
            errorsAlwaysTakeUpSpace={false}
            options={{
              required: 'E-postadress måste anges',
              pattern: {
                value: /^\S+@\S+$/i,
                message: 'Ogiltig e-postadress',
              },
            }}
          />
        </FormLabel>
      </Form>
    </InformationModal>
  );
};
