import { useMutation, useQueryClient } from '@tanstack/react-query';

import { PostAlarm } from './types';
import { fetchWithErrorThrowing } from 'ck-utils';
import toast from 'react-hot-toast';

export const useAlarmCreate = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: PostAlarm) =>
      toast.promise(
        createAlarm(data),
        {
          loading: 'Laddar...',
          success: 'Larm skickat',
          error: 'Kunde inte skapa larm',
        },
        { duration: 8000 }
      ),
    // If connection is lost we don't want it to queue up mutations
    networkMode: 'always',
    onSuccess: () => {
      // Invalidate all notifications queries to update the UI
      queryClient.invalidateQueries(['notifications']);
    },
  });
};

// Mutation call
async function createAlarm(data: PostAlarm) {
  return fetchWithErrorThrowing('/notifications/alarm', {
    method: 'POST',
    body: JSON.stringify({ ...data, targetGroup: data.targetGroup.group }),
    headers: new Headers({ 'content-type': 'application/json' }),
  });
}
