import { EditInfo, MainTitle } from 'ck-components';
import { Navigate, useParams } from 'react-router-dom';
import { PhoneMockConfig, ThemeFormData } from 'src/queries/themes/types';
import { useCallback, useEffect, useState } from 'react';

import { CreateThemeForm } from 'src/widgets';
import { FullScreenLoadingSpinner } from 'src/components/FullScreenLoadingSpinner';
import { PhoneMockWithToggle } from 'src/components/PhoneMock/PhoneMockWithToggle';
import { defaultPhoneMockConfig } from 'src/widgets/CreateThemeForm/defaults';
import { useThemePrefill } from 'src/queries/themes/useThemePrefill';

interface Props {
  isEdit?: boolean;
  isCopy?: boolean;
}
export function ThemesCreatePage({ isCopy, isEdit }: Props) {
  const { id } = useParams();
  const { data, isLoading } = useThemePrefill(id, Boolean(isCopy));
  const prefill = data?.prefill;
  // State set by the form, used to render the phone preview
  const [config, setConfig] = useState<PhoneMockConfig>(defaultPhoneMockConfig);
  // What view PhoneMockWithToggle component is showing
  const [index, setIndex] = useState<number>(0);

  // Callback to update the state when the form changes
  const onFormChange = useCallback(
    (data: ThemeFormData) => {
      setConfig({
        title: data.title,
        themeLinkButtonText: data.themeLinkButtonText,
        pngImage: data.image || null,
        countdownButtonEnabled: data.countdownButtonType !== 'none',
        hasCountdown: data.countdownEnabled === 'enabled',
        theme: data.theme,
      });
    },
    [setConfig]
  );

  // Can only set a copied theme if it has been fetched
  useEffect(() => {
    if (!isLoading && prefill) {
      setConfig({
        title: prefill.title,
        pngImage: prefill.image,
        theme: prefill.theme,
        hasCountdown: prefill.countdownEnabled === 'enabled',
        themeLinkButtonText: prefill.themeLinkButtonText,
        countdownButtonEnabled: prefill.countdownButtonType !== 'none',
      });
    }
  }, [isLoading, prefill]);

  if (!data && id && !isLoading) {
    // If there is no copy/edit data just go to normal form
    return <Navigate to='/themes/create' replace={true} />;
  }
  if (isLoading) {
    return <FullScreenLoadingSpinner />;
  }

  const mainTitle = isEdit
    ? 'Redigera tema'
    : isCopy && prefill?.title
    ? prefill.title
    : 'Skapa tema';
  return (
    <div className='flex w-full gap-10'>
      <div className='flex flex-col gap-6'>
        {prefill && (
          // Very ugly, theme works differently than all other forms
          // TODO fix when the API is similar for all forms
          <EditInfo isDraft={isEdit && Number(prefill.status) === 4} />
        )}
        <MainTitle label={mainTitle} />

        <CreateThemeForm
          handleFormChange={onFormChange}
          prefill={prefill}
          isCopy={isCopy}
          isEdit={isEdit}
        />
      </div>
      <div className='flex w-full max-w-xl justify-center'>
        <PhoneMockWithToggle
          {...config}
          index={index}
          setSelectedIndex={setIndex}
        />
      </div>
    </div>
  );
}
