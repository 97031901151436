import {
  PostCreateEventV1,
  PostPollsRequest,
  PostPushNotification,
  ThemeFormData,
  User,
} from 'ck-queries';

import { ImageInLocalStorage } from './convertBase64ImageToFile';

export type WithLocalStorageImage<T> = Omit<T, 'image'> & {
  image: ImageInLocalStorage | null;
};

export type LocalStorageTypes = {
  USER: User | null;
  FORM_CREATE_NOTIFICATION: PostPushNotification;
  FORM_CREATE_ALARM: PostPushNotification;
  FORM_CREATE_EVENT: WithLocalStorageImage<PostCreateEventV1>;
  FORM_CREATE_THEME: WithLocalStorageImage<Omit<ThemeFormData, 'targetGroup'>>;
  FORM_CREATE_POLL: WithLocalStorageImage<
    Omit<PostPollsRequest, 'targetGroup'>
  >;
};

/**
 * A typed wrapper around the localStorage object that only accepts explicitly typed keys.
 */
export const localStorageTyped = {
  /**
   * Gets the value for the specified key from localStorage.
   * The response from .get() is already JSON.parsed and returns unknown to force explicit typing on use instead of any.
   * @example localStorageTyped.get('USER')
   * @param {K extends keyof LocalStorageTypes} key - The key to retrieve from localStorage.
   * @returns {LocalStorageTypes[K]} - The value for the specified key.
   */
  get: <K extends keyof LocalStorageTypes>(key: K): LocalStorageTypes[K] =>
    JSON.parse(localStorage.getItem(key) as string),

  /**
   * Sets the value for the specified key in localStorage.
   * The input value gets JSON.stringify inside the function.
   * @param {K extends keyof LocalStorageTypes} key - The key to set in localStorage.
   * @param {LocalStorageTypes[K]} input - The value to set for the specified key.
   */
  set: <K extends keyof LocalStorageTypes>(
    key: K,
    input: LocalStorageTypes[K]
  ) => localStorage.setItem(key, JSON.stringify(input)),

  /**
   * Removes the specified key and its value from localStorage.
   * @param {K extends keyof LocalStorageTypes} key - The key to remove from localStorage.
   */
  remove: <K extends keyof LocalStorageTypes>(key: K) =>
    localStorage.removeItem(key),

  /**
   * Removes all keys and their values from localStorage.
   */
  clear: () => localStorage.clear(),
};
