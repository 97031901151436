export enum TargetGroupEnum {
  All = 'All',
  Alumni = 'Alumni',
  Freshmen = 'Freshmen',
  Students = 'Student',
}

export type TargetGroupsResponse = {
  targetGroups: Array<TargetGroup>;
};

export type TargetGroup = {
  group: TargetGroupEnum;
  count: string;
};
