import { Form, FormTextInput, PasswordInput } from 'ck-components';
import { SubmitHandler, useForm } from 'react-hook-form';
import { UserType, useUserRegister } from 'ck-queries';

import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export interface Props {
  email: string;
  token: string;
  userType: UserType;
}

interface RegisterUserFormValues {
  email: string;
  password: string;
  passwordConfirm: string;
}

/**
 * Form which handles the registration of a new admin or moderator via an invitation email.
 * @param email The email from the invitation
 * @param token The token from the invitation
 * @param userType The type of user to register (admin or moderator)
 */

export const RegisterUserForm = ({ email, token, userType }: Props) => {
  const defaults = { email, password: '', passwordConfirm: '' };

  const form = useForm<RegisterUserFormValues>({ defaultValues: defaults });
  const { handleSubmit, setValue, watch } = form;

  const M_registerUser = useUserRegister(userType);
  const navigate = useNavigate();

  // Set email from props in form
  useEffect(() => {
    setValue('email', email);
  }, [email, setValue]);

  const onSubmit: SubmitHandler<RegisterUserFormValues> = (data) => {
    const { password } = data;
    M_registerUser.mutateAsync({ email, password, token })
      .then(() => {
        form.reset();
      })
      .catch(() => {
        form.reset();
      });
  };

  return (
    <>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        submitButtonLabel='Skapa konto'
        customFormStyle='gap-2'
        submitButtonStyle='w-full'
        form={form}
        isSubmitting={M_registerUser.isLoading}
      >
        <FormTextInput
          label='E-postadress'
          parentForm={form}
          disabled
          name='email'
          errorsAlwaysTakeUpSpace={false}
        />

        <PasswordInput
          label='Välj lösenord'
          name='password'
          parentForm={form}
          errorsAlwaysTakeUpSpace={false}
          minLength={6}
          options={{
            validate: (val) =>
              val === watch('passwordConfirm') || 'Lösenorden matchar inte',
          }}
        />

        <PasswordInput
          label='Bekräfta lösenord'
          name='passwordConfirm'
          parentForm={form}
          minLength={6}
          errorsAlwaysTakeUpSpace={false}
          className='mb-6'
          options={{
            validate: (val) =>
              val === watch('password') || 'Lösenorden matchar inte',
          }}
        />
      </Form>
      <div
        className='mt-6 flex cursor-pointer justify-center text-sm text-sky-500 underline hover:text-sky-600'
        onClick={() => navigate('/login')}
      >
        Tillbaka till inloggning
      </div>
    </>
  );
};
