import { Checkmark } from 'ck-assets';
import { DropdownOption } from './TargetGroupDropdownSelector';
import { Listbox } from '@headlessui/react';
import { ReactSVG } from 'react-svg';
import clsx from 'clsx';
import { translateTargetGroup } from 'src/queries/targetGroups/translateTargetGroup';

interface DropdownListProps {
  selectedOption: DropdownOption;
  options: DropdownOption[];
}
/**
 * Sub-component of DropdownSelector.tsx
 * Separated to make it readable.
 *
 * @returns DropdownList for use in DropdownSelector
 */
export const DropdownList = ({
  selectedOption,
  options,
}: DropdownListProps) => (
  <Listbox.Options
    className={clsx(
      'absolute z-50 flex w-full flex-col overflow-y-auto rounded border-2 border-gray-300',
      !selectedOption && '-mt-[1px]'
    )}
  >
    <div className='relative max-h-[300px]'>
      {options.map((option, idx) => (
        <Listbox.Option
          as='div'
          key={`${option.group}-${idx}`}
          value={option}
          className='flex h-14 w-full cursor-pointer items-center border-b border-gray-300 bg-white px-4'
        >
          <li className='flex w-full items-center justify-between'>
            {`${translateTargetGroup(option.group)} (${option.count} st)`}
            {option.group === selectedOption.group && (
              <ReactSVG src={Checkmark} />
            )}
          </li>
        </Listbox.Option>
      ))}
    </div>
  </Listbox.Options>
);
