import {
  ColorPicker,
  DropdownOption,
  DropdownSelector,
  FormLabel,
  FormTextInput,
  RadioButtons,
  SingleSelectDropdown,
  SubTitle,
} from 'ck-components';
import { ThemeFormData, useEventsIdName, useTargetGroups } from 'ck-queries';

import { FullScreenLoadingSpinner } from 'src/components/FullScreenLoadingSpinner';
import { UseFormReturn } from 'react-hook-form';
import dayjs from 'dayjs';

interface Props {
  form: UseFormReturn<ThemeFormData>;
}

export const TemaSection = ({ form }: Props) => {
  const Q_targetGroups = useTargetGroups();
  const Q_EventsIdName = useEventsIdName();
  const themeLinkButtonType = form.watch('themeLinkButtonType');
  const end = form.watch('end');
  const start = form.watch('start');

  if (
    Q_targetGroups.isLoading ||
    Q_EventsIdName.isLoading ||
    !Q_EventsIdName.data
  ) {
    return <FullScreenLoadingSpinner />;
  }

  return (
    <div className=''>
      <SubTitle label='1. Tema' />
      <div>
        <FormTextInput
          label={'NAMNGE TEMA'}
          name='title'
          parentForm={form}
          maxLength={40}
        />
        {Q_targetGroups.data && (
          <div className='mb-8 w-full'>
            <DropdownSelector
              label='VISAS FÖR'
              name='targetGroup'
              defaultValue={Q_targetGroups.data[0]}
              emptyStateLabel={'Laddar...'}
              parentForm={form}
              onChange={(selected: DropdownOption) =>
                form.setValue('targetGroup', selected)
              }
              optionsData={Q_targetGroups.data || []}
            />
          </div>
        )}
      </div>
      <div className='flex items-center gap-4'>
        <FormTextInput
          label={'START'}
          parentForm={form}
          name='start'
          type='datetime-local'
          minDate={dayjs().format('YYYY-MM-DDTHH:mm')}
          options={{
            validate: {
              startIsInFuture: (value) =>
                dayjs().isBefore(value) || 'Startdatum måste vara i framtiden',
              startIsBeforeEnd: (value) =>
                dayjs(value).isBefore(dayjs(end)) ||
                'Startdatum måste vara innan slutdatum',
            },
          }}
        />
        <div className='text-2xl font-thin'>-</div>
        <FormTextInput
          label={'SLUT'}
          parentForm={form}
          name='end'
          type='datetime-local'
          minDate={dayjs().format(start)}
          options={{
            validate: (value) =>
              dayjs(value).isAfter(start) ||
              'Slutdatum måste vara efter startdatum',
          }}
        />
      </div>
      <FormLabel label='KNAPP STARTSIDA'>
        <div className='mb-6'>
          <RadioButtons
            name='themeLinkButtonType'
            parentForm={form}
            onChange={(e) => {
              switch (e.target.value) {
                case 'none': {
                  form.unregister('themeLinkButtonText');
                  form.unregister('themeLinkUrl');
                  form.unregister('themeButtonEventId');
                  form.unregister('theme.palette.section.home.themeLinkButton');
                  break;
                }
                case 'in-app': {
                  form.unregister('themeLinkUrl');
                  break;
                }
                case 'URL': {
                  form.unregister('themeButtonEventId');
                  break;
                }
              }
            }}
            selectOptions={[
              { label: 'Ingen knapp', value: 'none' },
              { label: 'Öppna event i appen', value: 'in-app' },
              { label: 'Öppna URL i webbläsare', value: 'url' },
            ]}
          />
        </div>
      </FormLabel>
      {themeLinkButtonType !== 'none' && (
        <FormTextInput
          label={'KNAPPTEXT'}
          parentForm={form}
          name='themeLinkButtonText'
          type='textfield'
          maxLength={22}
        />
      )}
      {themeLinkButtonType === 'in-app' && (
        <SingleSelectDropdown
          label='VÄLJ EVENT'
          description='Välj event'
          name='themeButtonEventId'
          parentForm={form}
          optionsData={Q_EventsIdName.data.map(({ name }) => name)}
          useOtherValues={Q_EventsIdName.data.map(({ id }) => id)}
        />
      )}
      {themeLinkButtonType === 'url' && (
        <FormTextInput
          label={'LÄNK'}
          parentForm={form}
          name='themeLinkUrl'
          type='textfield'
        />
      )}
      {themeLinkButtonType !== 'none' && (
        <ColorPicker
          label='KNAPP STARTSIDA'
          name='theme.palette.section.home.themeLinkButton'
          form={form}
        />
      )}
    </div>
  );
};
