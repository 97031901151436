import { EditInfo, MainTitle } from 'ck-components';
import { Navigate, useParams } from 'react-router-dom';

import { FullScreenLoadingSpinner } from 'src/components/FullScreenLoadingSpinner';
import { PollsCreateForm } from 'ck-widgets';
import { usePollsPrefill } from 'ck-queries';

interface Props {
  isEdit?: boolean;
  isCopy?: boolean;
}

export function PollsCreatePage({ isEdit, isCopy }: Props) {
  const { id } = useParams();
  const { data, isLoading, isError } = usePollsPrefill(id, Boolean(isCopy));
  const prefill = data?.prefill;

  if (isLoading) {
    return <FullScreenLoadingSpinner />;
  }

  if (isError) {
    return (
      <p>
        Något gick fel vid inläsning av sidan. Prova uppdatera din webbläsare.
      </p>
    );
  }

  if (!prefill && id) {
    return <Navigate to='/polls/create' replace={true} />;
  }

  const mainTitle = isEdit
    ? 'Redigera omröstning'
    : isCopy && prefill?.titleSwedish
    ? prefill.titleSwedish
    : 'Skapa pushnotis';

  return (
    <div className='flex flex-1 flex-col'>
      {prefill && <EditInfo isDraft={isEdit && prefill.isDraft} />}
      <MainTitle label={mainTitle} customStyle='pb-8' />

      <PollsCreateForm isCopy={isCopy} isEdit={isEdit} prefill={prefill} />
    </div>
  );
}
