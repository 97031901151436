import {
  DivisionPage,
  EventArchivePage,
  EventCreatePage,
  EventSwishPage,
  LoginPage,
  Organisations,
  PollsArchivePage,
  PollsCreatePage,
  PushNotificationsArchivePage,
  PushNotificationsCreatePage,
  RedirectToRelevantStartPage,
  RegisterUserPage,
  ResetPasswordPage,
  RouterErrorPage,
  SettingsPage,
  ThemesArchivePage,
  ThemesCreatePage,
} from 'ck-pages';

import { AlarmCreatePage } from './pages/AlarmCreatePage.tsx/AlarmCreatePage';
import Root from './root';
import { createBrowserRouter } from 'react-router-dom';

// This is the routing tree of the app
export const router = createBrowserRouter([
  {
    // The unauthenticated login route
    path: '/login',
    element: <LoginPage />,
    errorElement: <RouterErrorPage />,
  },
  {
    path: '/resetpassword/:token',
    element: <ResetPasswordPage />,
    errorElement: <RouterErrorPage />,
  },
  {
    path: 'division/invite/:token',
    element: <RegisterUserPage group={'divisions'} />,
    errorElement: <RouterErrorPage />,
  },
  {
    path: 'committee/invite/:token',
    element: <RegisterUserPage group={'committees'} />,
    errorElement: <RouterErrorPage />,
  },
  {
    // The authenticated routes
    path: '/',
    // ! Root has additional permissions-based routing inside of it
    element: <Root />, //Here Root is set to wrap all children
    errorElement: <RouterErrorPage />,
    children: [
      {
        path: '/',
        element: <RedirectToRelevantStartPage />,
      },
      {
        path: 'push-notifications/',
        children: [
          {
            path: 'create',
            element: <PushNotificationsCreatePage />,
          },
          {
            path: 'copy/:id',
            element: <PushNotificationsCreatePage isCopy />,
          },
          {
            path: 'edit/:id',
            element: <PushNotificationsCreatePage isEdit />,
          },
          {
            path: 'archive',
            element: <PushNotificationsArchivePage />,
          },
          {
            path: 'alarm',
            children: [
              { path: 'create', element: <AlarmCreatePage /> },
              { path: 'copy/:id', element: <AlarmCreatePage isCopy /> },
            ],
          },
        ],
      },
      {
        path: 'themes/',
        children: [
          {
            path: 'create',
            element: <ThemesCreatePage />,
          },
          {
            path: 'archive',
            element: <ThemesArchivePage />,
          },
          {
            path: 'copy/:id',
            element: <ThemesCreatePage isCopy />,
          },
          {
            path: 'edit/:id',
            element: <ThemesCreatePage isEdit />,
          },
        ],
      },
      {
        path: 'polls/',
        children: [
          {
            path: 'create',
            element: <PollsCreatePage />,
          },
          {
            path: 'edit/:id',
            element: <PollsCreatePage isEdit />,
          },
          {
            path: 'copy/:id',
            element: <PollsCreatePage isCopy />,
          },
          {
            path: 'archive',
            element: <PollsArchivePage />,
          },
        ],
      },
      {
        path: 'events/',
        children: [
          {
            path: 'create',
            element: <EventCreatePage />,
          },
          {
            path: 'edit/:id',
            element: <EventCreatePage isEdit />,
          },
          {
            path: 'copy/:id',
            element: <EventCreatePage isCopy />,
          },
          {
            path: 'archive',
            element: <EventArchivePage />,
          },
          {
            path: 'swish',
            element: <EventSwishPage />,
          },
        ],
      },
      {
        path: 'organisations/',
        children: [
          {
            path: '',
            element: <Organisations />,
          },
          {
            path: ':divisionId',
            element: <DivisionPage />,
          },
        ],
      },
      {
        path: 'settings',
        element: <SettingsPage />,
      },
    ],
  },
]);
