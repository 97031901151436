import { Form, FormTextInput, InformationModal } from 'ck-components';
import { PostResetPasswordRequestV1, usePasswordReset } from 'ck-queries';
import { SubmitHandler, useForm } from 'react-hook-form';

import { APIError } from 'src/typesGlobal';

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export function ForgotPasswordModal({ isOpen, setIsOpen }: Props) {
  const form = useForm<PostResetPasswordRequestV1>({ mode: 'onSubmit' });

  const { handleSubmit, reset } = form;

  const M_ResetPassword = usePasswordReset();

  const onSubmit: SubmitHandler<PostResetPasswordRequestV1> = ({ email }) => {
    M_ResetPassword.mutateAsync({ email })
      .then(() => setIsOpen(false))
      .catch((err) => {
        throw new APIError(err);
      });
  };

  return (
    <InformationModal
      modalTitle='Glömt lösenord'
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      showCloseButton={false}
      customStyle='min-w-fit'
    >
      <Form
        onSubmit={handleSubmit(onSubmit)}
        submitButtonLabel={'Återställ'}
        showCancelButton
        onCancel={() => {
          setIsOpen(false);
          reset();
        }}
        explicitDisabled={M_ResetPassword.isLoading}
        centerButtons
        submitButtonStyle='w-[200px] px-4 h-[60px]'
        cancelButtonStyle='w-[200px] px-4 h-[60px]'
        form={form}
        isSubmitting={M_ResetPassword.isLoading}
      >
        <p className='max-w-md text-lg'>
          Ange din mailadress så skickar vi en länk för att återställa ditt
          lösenord. Länken är giltig i 24 timmar.
        </p>

        <FormTextInput
          label='e-postadress'
          name='email'
          parentForm={form}
          options={{
            pattern: {
              value:
                // eslint-disable-next-line no-useless-escape
                /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
              message: 'Det måste vara en giltig e-postadress',
            },
          }}
        />
      </Form>
    </InformationModal>
  );
}
