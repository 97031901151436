import { TargetGroupEnum } from './types';

export const translateTargetGroup = (targetGroup: TargetGroupEnum) => {
  switch (targetGroup) {
    case 'Alumni':
      return 'Alumner';
    case 'Freshmen':
      return 'Nollan';
    case 'Student':
      return 'Studenter';
    case 'All':
      return 'Studenter & Alumner';
    default:
      return undefined;
  }
};
