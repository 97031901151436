import { Poll, PollFromServer } from './types';
import { fetchWithErrorThrowing, standardDateFormat } from 'ck-utils';

import { QK } from '../queryKeys';
import dayjs from 'dayjs';
import { translateTargetGroup } from '../targetGroups/translateTargetGroup';
import { useQuery } from '@tanstack/react-query';

type PollsListType = 'scheduled' | 'history';

// Query hook
export const usePolls = (type: PollsListType) =>
  useQuery({
    queryKey: type === 'scheduled' ? QK.polls.scheduled : QK.polls.history,
    queryFn: () => getPolls(type),
  });

// Query function
export async function getPolls(type: PollsListType) {
  const res = await fetchWithErrorThrowing(`/polls/${type}`, {
    method: 'GET',
  });

  const data: { polls: PollFromServer[] } = await res.json();

  const polls: Poll[] = data.polls.map((m) => {
    return {
      ...m,
      scheduleTime: dayjs(m.scheduleTime).format(standardDateFormat),
      sent: m.sent ? dayjs(m.sent).format(standardDateFormat) : undefined,
      targetGroupString: `${translateTargetGroup(m.targetGroup.group)} (${
        m.targetGroup.count
      } st)`,
      openedByString: `${m.openedByCount} av ${m.targetGroup.count}`,
    };
  });

  return polls;
}
